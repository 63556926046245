<template>
  <div id="changelog">

    <h2>Changelog</h2>

    <div class="item">
      <h3>v26</h3>
      <h4>27/10/2021</h4>
      <ul>
        <li>Mostrando uma notifição para o usuário quando uma nova empresa for cadastrada.</li>
      </ul>
    </div>

    <div class="item">
      <h3>v25</h3>
      <h4>27/10/2021</h4>
      <ul>
        <li>Simplificando e unificando endpoint de cadastro</li>
      </ul>
    </div>

    <div class="item">
      <h3>v24</h3>
      <h4>22/01/2021</h4>
      <ul>
        <li>Ativando novo número do Whatsapp do comercial.</li>
      </ul>
    </div>
    
    <div class="item">
      <h3>v23</h3>
      <h4>12/01/2021</h4>
      <ul>
        <li>Ativando Facebook Pixel para campanhas de publicidade.</li>
      </ul>
    </div>

    <div class="item">
      <h3>v22</h3>
      <h4>08/12/2020</h4>
      <ul>
        <li>Estamos forçando que o texto digitado no formulário de cadastro, fique em caixa alta nos campos texto e caixa baixa no e-mail. Evitar problema com token.</li>
      </ul>
    </div>
    <div class="item">
      <h3>v21</h3>
      <h4>08/10/2020</h4>
      <ul>
        <li>Validação do e-mail agora considera o cadastro dos usuários, além do e-mail do cadastro da empresa principal.</li>
      </ul>
    </div>

    <div class="item">
      <h3>v20</h3>
      <h4>20/08/2020</h4>
      <ul>
        <li>Correção na forma que o conteúdo do contrato de adesão estava sendo carregado.</li>
      </ul>
    </div>

    <div class="item">
      <h3>v19</h3>
      <h4>20/08/2020</h4>
      <ul>
        <li>Contrato de adesão centralizado ao Cube.</li>
      </ul>
    </div>

    <div class="item">
      <h3>v18</h3>
      <h4>19/08/2020</h4>
      <ul>
        <li>Alteração dos termos de uso para contrato de adesão</li>

      </ul>
    </div>
    <div class="item">
      <h3>v17</h3>
      <h4>08/10/2019</h4>
      <ul>
        <li>Inclusão de campo para usuário aceitar os temos de uso, na hora de fazer o cadastro</li>

      </ul>
    </div>

    <div class="item">
      <h3>v16</h3>
      <h4>30/11/2018</h4>
      <ul>
        <li>Correção na rotina que faz a requisição para criar novo usuário, a senha não estava gravando conforme informado pelo usuário.</li>

      </ul>
    </div>

    <div class="item">
      <h3>v15</h3>
      <h4>31/10/2018</h4>
      <ul>
        <li>Alteração do link do vídeo de boas vindas para https://www.youtube.com/watch?v=TbM3YkDz-_c</li>
        <li>Adicionado logo do vex na mensagem de boas vindas</li>
      </ul>
    </div>

    <div class="item">
      <h3>v14</h3>
      <h4>29/10/2018</h4>
      <ul>
        <li>Melhoria na mensagem de boas-vindas, ajuste da largura do iframe do youtube para 100% para ficar adequado a todas as resoluções.</li>

      </ul>
    </div>

    <div class="item">
      <h3>v13</h3>
      <h4>29/10/2018</h4>
      <ul>
        <li>Correção na rotina que faz a passagem do novo token criado para a nova empresa cadastrada</li>
        <li>Alterado e-mail de aviso quando nova versão do aplicativo é liberada, com melhorias no assunto e link para o changelog</li>
      </ul>
    </div>

    <div class="item">
      <h3>v12</h3>
      <h4>29/10/2018</h4>
      <ul>
        <li>Nova tela dedicada a mensagem de boas vindas. Não é mais mostrada em modal</li>
      </ul>
    </div>

    <div class="item">
      <h3>v11</h3>
      <h4>29/10/2018</h4>
      <ul>
        <li>Incluído página para controlarmos o changelog do aplicativo de cadastro do VEX</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
  #changelog h2 { margin-bottom: 30px; }
  .item { border-bottom: 1px solid #f2f2f2; margin-bottom: 20px; }
  .item h3 {margin-bottom: 20px;}
  .item h4 { color:gray; font-size:12pt; margin-bottom: 20px;}
</style>
