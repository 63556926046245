<template>
  <div>
<!--    <h1>Política de Privacidade</h1>-->
<!--    <br />-->
<!--    <h2>Aceitação</h2>-->
<!--    <br />-->
<!--    <p>A aceitação livre, consciente, expressa, informada, plena e sem reservas, de todos os itens destes Termos ocorrerá no ato do seu clique no botão “Aceito os Termos”, ao efetuar o Download do App VEX. Caso Você não aceite ou não concorde com quaisquer das disposições abaixo, ou quaisquer alterações realizadas nestes Termos, deverá se abster de utilizar o App VEX.</p>-->
<!--    <br />-->
<!--    <h2>Acesso e Funcionalidades</h2>-->
<!--    <br />-->
<!--    <p>Estes Termos visam regular a utilização do App VEX, disponível para acesso por meio de smartphone com tecnologia Android®, para os clientes contratantes do sistema de Vistoria VEX, por meio de download pela Play Store. O App VEX é disponibilizado com o intuito de integrar o sistema Web de Vistoria Veicular disponível em http://app.vexsoft.com.br.</p>-->
<!--    <p>Para utilização do App VEX, uma senha de acesso deverá ser liberada pela InovaClick Software. Depois da liberação, você deverá realizar o processo de sincronização no App VEX. Após o aceite destes Termos, você deverá realizar seu cadastro no App VEX, fornecendo todos os dados solicitados pela InovaClick Software. Após referido cadastro, Você poderá desfrutar da comodidade oferecida pelo App VEX e realizar a Vistoria necessária para a contratação do Software.</p>-->
<!--    <p>Após informar seus dados para utilização do App VEX, você poderá iniciar a Vistoria e confirmar em seguida, os dados serão encaminhados para o servidor e disponibilizado uma cópia para o cliente, além de ter os dados no sistema Web de Vistoria Veicular disponível em app.vexsoft.com.br.</p>-->
<!--    <p>Você reconhece que é o único responsável por todas as informações fornecidas quando de seu acesso ao App VEX, responsabilizando-se, com exclusividade e inclusive perante terceiros, por quaisquer danos ou prejuízos decorrentes de informações incorretas, incompletas ou inverídicas inseridas no App VEX, inclusive pela influência destas sobre o resultado da Vistoria.</p>-->
<!--    <p>Você declara ter ciência de que a inclusão de qualquer informação ou imagem inverídica, falsa ou adulterada o sujeitará a todas as sanções aplicáveis constantes da legislação brasileira, inclusive em âmbito criminal.</p>-->
<!--    <br />-->
<!--    <h2>Declarações do Usuário e Regras de Utilização</h2>-->
<!--    <br />-->
<!--    <p>Ao manifestar o aceite aos presentes Termos, você declara que:</p>-->
<!--    <p>É considerado civilmente capaz à luz da legislação brasileira, ou encontra-se devidamente autorizado por seus responsáveis legais a aceitar o presente Termo e utilizar o App VEX;</p>-->
<!--    <p>Está ciente de que não poderá inserir no App VEX qualquer conteúdo ilícito ou de qualquer modo contrário à moral e aos bons costumes ou que viole direitos de terceiros, bem como que arquivos suspeitos, vírus ou outros elementos nocivos no App VEX ou que prejudique o sistema da InovaClick Software; e</p>-->
<!--    <p>É o responsável, com exclusividade, pela utilização que fizer do App VEX, eximindo a InovaClick Software de qualquer responsabilidade por qualquer dano ou prejuízo decorrente de seu uso.</p>-->
<!--    <p>Você reconhece que, em qualquer hipótese, será o único responsável pelo uso que fizer do App VEX, bem como por qualquer conteúdo nele inserido.</p>-->
<!--    <br />-->
<!--    <h2>Duração e Finalização do Acesso</h2>-->
<!--    <br />-->
<!--    <p>O acesso ao App VEX é disponibilizado a você por prazo indeterminado, podendo a InovaClick Software descontinuar o App VEX a qualquer tempo, independentemente de prévio aviso, não sendo devido qualquer tipo de indenização a você em razão disso.</p>-->
<!--    <p>A InovaClick Software pode, a qualquer momento, limitar o seu acesso ao App VEX, podendo negá-lo ou suspendê-lo, o que pode ensejar a exclusão de todas as informações fornecidas, sem que seja devida qualquer indenização ou compensação.</p>-->
<!--    <br />-->
<!--    <h2>Privacidade</h2>-->
<!--    <br />-->
<!--    <p>Quando você utiliza o App VEX Vistoria a InovaClick Software terá acesso a câmera de seu aparelho smartphone, e poderá coletar e armazenar informações inseridas ativamente por você no App VEX.</p>-->
<!--    <p>Quando você utiliza o App VEX Vistoria a InovaClick Software poderá ter acesso ao status da rede, bem como acesso a internet de seu aparelho smartphone, e poderá coletar e armazenar informações inseridas ativamente por você no App VEX.</p>-->
<!--    <p>Poderão ser coletadas, ainda, algumas informações automaticamente quando você acessa e utiliza o App VEX, tais quais características do dispositivo de acesso, do navegador, Protocolo de Internet (IP, com data e hora), origem do IP, pontos de acesso Wi-Fi, torres de redes móveis, dados do GPS, informações sobre cliques, páginas acessadas, as páginas seguintes acessadas após a saída do App VEX, ou qualquer termo de busca digitado nele, dentre outros. Ademais, poderão ser utilizadas algumas tecnologias padrões para coletar as suas informações, especialmente cookies, de modo a melhorar sua experiência de usabilidade.</p>-->
<!--    <p>Desta forma, por meio do Aceite destes Termos, você se declara ciente das informações coletadas por meio do App VEX e expressa consentimento livre, consciente, expresso e informado com relação à coleta de tais informações.</p>-->
<!--    <p>A InovaClick Software considera todas as informações coletadas por meio do App VEX como confidenciais, garantindo que serão tratadas e armazenadas conforme as determinações destes Termos e com a adoção das adequadas medidas de segurança. As informações cadastradas e coletadas no App VEX são utilizadas para a devida prestação de serviços pela InovaClick Software, para melhorar a sua experiência de navegação no App VEX, bem como para fins publicitários e estatísticos, conforme a seguir detalhado.</p>-->
<!--    <p>Você reconhece que suas informações poderão ser utilizadas para fins estatísticos, pela InovaClick Software ou por terceiros, desde que mediante a anonimização de referidos dados, de modo que você não possa ser identificado na elaboração e emissão dos relatórios estatísticos.</p>-->
<!--    <p>Da mesma forma, por meio do aceite destes Termos, você consente expressamente em receber e-mails promocionais por parte da InovaClick Software, bem como de seus parceiros e eventuais outros comunicados correlacionados. Caso não deseje receber essas mensagens, você deverá entrar em contato com a InovaClick Software, por meio do e-mail play@inovaclick.com.br , solicitando a cessação do recebimento dessas mensagens.</p>-->
<!--    <p>Por meio de seu aceite, você manifesta seu consentimento livre, expresso e informado de que os dados coletados sobre você serão utilizados exclusivamente para as finalidades dispostas neste termo.</p>-->
<!--    <p>Você reconhece que a InovaClick Software poderá compartilhar os dados coletados por meio do App VEX com terceiros, apenas nas seguintes situações:</p>-->
<!--    <p>Quando necessário às atividades comerciais e serviços prestados pela empresa, como por exemplo, com avaliadores responsáveis pela vistoria, entre outros;</p>-->
<!--    <p>Com empresas parceiras para fins publicitários, estatísticos e para a prestação dos serviços relacionados às funcionalidades disponibilizadas no App VEX, conforme descrito acima;</p>-->
<!--    <p>Para proteção dos interesses da InovaClick Software, em qualquer tipo de conflito, incluindo ações judiciais;</p>-->
<!--    <p>No caso de operações societárias envolvendo a InovaClick Software, hipótese em que a transferência das informações será necessária para a continuidade dos serviços; ou</p>-->
<!--    <p>e) Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.</p>-->
<!--    <p>As suas informações serão armazenadas pela InovaClick Software em servidores próprios ou por ela contratados. São empregados todos os esforços razoáveis de mercado para garantir a segurança de seus sistemas na guarda de referidos dados, tais como:</p>-->
<!--    <p>Aplicação de métodos padrões e de mercado para criptografar e tornar anônimos os dados coletados;</p>-->
<!--    <p>Utilização de softwares de alta tecnologia para proteção contra acesso não autorizado aos sistemas, os quais são considerados como ambiente controlado e de segurança;</p>-->
<!--    <p>Acesso somente de pessoas previamente autorizadas aos locais onde são armazenadas as informações; e,</p>-->
<!--    <p>Aqueles que que obtiverem contato com as referidas informações deverão se comprometer a mantê-las em sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e as consequências de uma medida judicial nos moldes da legislação brasileira.</p>-->
<!--    <p>Você poderá requerer a exclusão dos dados sobre você coletados, entrando em contato com a InovaClick Software, pelo e-mail: play@inovaclick.com.br informando seu pedido. A InovaClick Software empreenderá todos os esforços para atender aos pedidos, no menor espaço de tempo possível. A exclusão dos dados acarretará no fim de seu acesso ao App VEX e poderá, ainda, depender do encerramento de seu contrato de Softwares junto à InovaClick Software.</p>-->
<!--    <p>Importante notar que, mesmo em caso de requisição de exclusão, a InovaClick Software respeitará o prazo de armazenamento mínimo de informações determinado pela legislação brasileira.</p>-->
<!--    <br />-->
<!--    <h2>Isenções de Responsabilidade</h2>-->
<!--    <br />-->
<!--    <p>A InovaClick Software não se responsabiliza:</p>-->
<!--    <p>Por eventuais indisponibilidades, erros ou falhas do App VEX, bem como por qualquer defraudação da utilidade que você possa ter atribuído ao App VEX, pela falibilidade do mesmo, nem por qualquer dificuldade de acesso;</p>-->
<!--    <p>Por erros ou inconsistências na transmissão de dados ou pela qualidade ou disponibilidade do sinal de Internet;</p>-->
<!--    <p>Pela existência de vírus ou outros elementos nocivos no App VEX que possam causar alterações nos seus sistemas informáticos (software e hardware) ou nos documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos e prejuízos que possam decorrer de quaisquer elementos nocivos;</p>-->
<!--    <p>Pelos danos e prejuízos de toda natureza que possam decorrer do conhecimento que terceiros não autorizados possam ter de quaisquer das informações fornecidas pelo App VEX, em decorrência de falha exclusivamente atribuível a você ou a terceiros que fujam a qualquer controle razoável.</p>-->
<!--    <p>A InovaClick Software se reserva o direito de descontinuar o App VEX a qualquer momento, independentemente de prévio aviso, não cabendo qualquer tipo de indenização aos Usuários.</p>-->
<!--    <br />-->
<!--    <h2>Propriedade Intelectual</h2>-->
<!--    <br />-->
<!--    <p>Todos os textos, imagens, fotografias, ícones, tecnologias, links e demais conteúdos audiovisuais ou sonoros, incluindo o software do App VEX, desenhos gráficos e códigos fonte, são de propriedade exclusiva da InovaClick Software ou de terceiro que autorizou sua utilização no App VEX, e estão protegidos pelas leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.279/96, 9.609/98, 9.610/98.</p>-->
<!--    <p>Todas as marcas, os nomes comerciais ou logotipos de qualquer espécie disponibilizados no App VEX são de propriedade da InovaClick Softwares ou de terceiro que permitiu o seu uso, sem que a utilização do App VEX possa ser entendida como autorização para que você possa citar as tais marcas, nomes comerciais e logotipos.</p>-->
<!--    <br />-->
<!--    <h2>Disposições Gerais</h2>-->
<!--    <br />-->
<!--    <p>Os casos fortuitos ou de força maior serão excludentes de responsabilidades das partes, na forma da Legislação Brasileira.</p>-->
<!--    <p>Qualquer cláusula ou condição deste instrumento que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições destes Termos, as quais permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão.</p>-->
<!--    <p>A falha da InovaClick Software em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.</p>-->
<!--    <p>Estes Termos poderão ser alterados a qualquer momento, sendo que a nova versão deste documento entrará em vigor no dia seguinte da publicação no App VEX. Você somente será comunicado da alteração dos Termos se houver obrigação legal em tal sentido.</p>-->
<!--    <br />-->
<!--    <h2>Legislação e Foro</h2>-->
<!--    <br />-->
<!--    <p>A presente relação jurídica é regida exclusivamente pelas leis brasileiras, inclusive eventuais ações decorrentes de violação dos seus termos e condições.</p>-->
<!--    <p>Fica eleito o Foro da Comarca de Maringá no Estado do Paraná para dirimir quaisquer dúvidas, questões ou litígios decorrentes dos presentes Termos, renunciando as partes a qualquer outro, por mais privilegiado que seja.</p>-->
<!--    <br />-->
  </div>
</template>
<style>
p {
  text-align: justify;
}
h2 {
  color: #b2d382;
}
</style>
<script>
export default {
    created() {
        window.location.href = 'https://vexsoft.com.br/politica-de-privacidade/'
    }
};
</script>
