// The Vue build version to load with the `import` command
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import './assets/styles/global.css';

// https://github.com/euvl/vue-notification
import Notifications from 'vue-notification';
Vue.use(Notifications)


// Modal
import VModal from "vue-js-modal";
Vue.use(VModal);

// VuePassword
import VuePassword from "vue-password";
Vue.component(VuePassword);

// VueMask
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

// Axios
import axios from "axios";
axios.defaults.baseURL = import.meta.env.VITE_ROOT_API;

// Socket
import VueSocketio from "vue-socket.io";
Vue.use(VueSocketio, import.meta.env.VITE_SOCKET);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  components: { App },
  template: "<App/>"
});
