<template>
    <div id="app">
        <notifications position="bottom left"/>
        <div class="container">
            <div class="row">
                <div class="col">
                    <img class="mt-2" src="./assets/logo-vex.png" style="text-align:center; margin-bottom:30px;"
                         width="200px">
                </div>

            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <router-view/>
                </div>
            </div>
        </div>

        <div class="row">
            <footer class="page-footer font-small blue" style="background-color: rgba(0,0,0,0.05)">

                <div class="footer-copyright text-center py-3">VEXSOFT
                    <br>CNPJ 11.084.089/0001-38<br>
                    Avenida Tamandaré, 251 - Sala 1204 - Centro - Maringá - PR, 87013-210<br><a href="mailto:contato@vexsoft.com.br">contato@vexsoft.com.br</a> - (44) 3028-5935
                </div>

            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
body {
    background-color: #EDF2F5;

}

.container {
    margin-top: 10px;
}

.col {
    text-align: center;
}

footer {
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px;
    font-size: 8pt;
}
</style>
