import Cadastro from '@/views/cadastro/app.vue'
import Changelog from '@/views/changelog/app.vue'
import ContratoAdesao from '@/views/contrato-adesao/app.vue'
import MensagemBoasVindas from '@/views/mensagem-boas-vindas/app.vue'
import PoliticaPrivacidade from '@/views/politica-privacidade/app.vue'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Cadastro',
      component: Cadastro
    },
    {
      path: '/mensagem-boas-vindas',
      name: 'MensagemBoasVindas',
      component: MensagemBoasVindas
    },
    {
      path: '/changelog',
      name: 'Changelog',
      component: Changelog
    },
    {
      path: '/politica-privacidade',
      name: 'PoliticaPrivacidade',
      component: PoliticaPrivacidade
    }
    ,
    {
      path: '/contrato-adesao',
      name: 'ContratoAdesao',
      component: ContratoAdesao
    }
  ]
})
