<template>
  <vue-editor :editorToolbar="customToolbar" disabled v-model="html"></vue-editor>
</template>

<style >
p {
  text-align: justify;
}
h2 {
  color: #b2d382;
}

.ql-container {
  border: none!important;
}
.ql-toolbar {
  border: none!important;
}
</style>

<script>
import axios from "axios"
import {VueEditor} from 'vue2-editor';
export default {
  components: {
    VueEditor
  },
  created() {
      window.location.href = 'https://pdf.vexsoft.com.br/Contrato_VEXSOFT_Modelo_V1.0.pdf'
    // this.mostrar()
  },
  data() {
    return {
      html: null,
      customToolbar: [[]]
    }
  },
  methods: {
    mostrar: function() {

      try {
        const vm = this;
        axios
          .get("/contrato-adesao")
          .then(result => {

            vm.html = result.data.conteudo;
          })
          .catch(error => {});
      }
      catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
