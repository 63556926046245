<template>
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col col-lg-6" style="text-align:center;">
                <h3 style="color:black;margin-bottom:10px;">Parabéns, seu período de testes
                    começou.</h3>
                <h6 style="color:black;margin-bottom:20px;">Você ja pode começar a fazer vistorias agora
                    mesmo,
                    <a href="https://play.google.com/store/apps/details?id=br.com.inovaclick.Vex&hl=pt_BR"
                       target="_blank">baixando</a>
                    o app.</h6>
                <h6 v-if="token" style="color:black;margin-bottom:20px;">Seu token de acesso é: <strong>{{token}}</strong></h6>
                <div class="video-youtube">
                    <iframe allow="autoplay; encrypted-media" allowfullscreen frameborder="0"
                            height="295"
                            src="https://www.youtube.com/embed/TbM3YkDz-_c?start=1&amp;autoplay=1&rel=0"
                            width="100%"></iframe>
                </div>
                <div class="botoes" style="margin-top: 3vh;margin-bottom: 3vh;">
                    <a class="btn btn-primary" href="https://www.youtube.com/channel/UCmEbbN5wxuQSv5wEJlbsTQw"
                       target="_blank">Acessar
                        canal do Youtube</a>
                    <a class="btn btn-success" type="submit" :href="'https://app.vexsoft.com.br/login.php?token=' + token + '&email=' + encodeURIComponent(email)">Acessar painel
                        administrativo
                    </a>
                </div>
            </div>
        </div>
        <a class="float whatsapp-icone"
           href="https://api.whatsapp.com/send?phone=5544991035935&text=Oi! Realizei meu cadastro no aplicativo VEX, o que faço agora?"
           target="_blank">
            <i class="fa fa-whatsapp my-float "></i>
        </a>
    </div>
</template>
<style>
.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 60px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    animation: pulse 2s infinite;
}

.whatsapp-icone:hover {

    color: #fff !important
}

.my-float {
    margin-top: 16px;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(90, 162, 78, 0);
    }

    50% {
        transform: scale(1.2);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
</style>
<script>
export default {
    computed: {
        token() {
            return this.$route.query.token
        },
        email() {
            return this.$route.query.email
        }
    }
}
</script>
