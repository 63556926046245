<template>
    <div class="cadastre-se">
        <div class="row justify-content-md-center">
            <div class="col col-lg-7 d-none d-sm-none d-lg-block d-lg-xl">
                <h2
                    style="margin-bottom:50px;"
                >Junte-se a mais de 1000 empresas e descubra como é fácil realizar uma vistoria</h2>
                <iframe
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    frameborder="0"
                    height="315"
                    src="https://www.youtube.com/embed/zQ5Buvjvi6I"
                    width="560"
                ></iframe>
            </div>

            <div class="col col-lg-5">
                <div v-if="loading" class="loading">
                    <p style="text-align:center">
                        Oi
                        <strong>{{ empresa.contato.nome }}</strong>!
                        <br/>Aguarde um instante, estamos configurando sua empresa...
                    </p>

                    <img src="./../../assets/loading.gif"/>
                </div>

                <div v-show="!loading" class="formulario-cadastro">
                    <div class="form-group">
                        <input
                            id="nomeEmpresa"
                            v-model="empresa.nome"
                            class="form-control"
                            placeholder="Qual o nome da sua empresa?"
                            type="text"
                        />
                    </div>

                    <!-- <div class="form-group">
                        <input
                            id="cnpjEmpresa"
                            v-model="empresa.cnpj"
                            class="form-control"
                            placeholder="Qual o cnpj da sua empresa?"
                            type="text"
                            maxlength="18"
                            ref="cnpjEmpresa"
                        />
                    </div> -->

                    <div class="form-group">
                        <input
                            id="nomeContato"
                            v-model="empresa.contato.nome"
                            class="form-control"
                            placeholder="Qual é o seu nome?"
                            type="text"
                        />
                    </div>


                    <div class="form-group">
                        <the-mask
                            v-model="empresa.contato.celular"
                            :masked="false"
                            class="form-control"
                            mask="(##) #####-####"
                            placeholder="Celular"
                        ></the-mask>
                    </div>

                    <div class="form-group">
                        <input
                            id="email"
                            v-model="empresa.contato.email"
                            class="form-control"
                            placeholder="Qual é o seu e-mail?"
                            type="email"
                            autocomplete="off"
                        />
                    </div>

                    <div class="form-group">
                        <password
                            v-model="empresa.contato.senha"
                            :secureLength="8"
                            :toggle="true"
                            defaultClass="form-control"
                            errorClass="Password__badge--error"
                            placeholder="Cadastre uma senha"
                            successClass="Password__badge--success"
                        />
                    </div>
                    <div class="form-group">
                        <input id="aceiteTermosUso" v-model="aceiteTermosUso" type="checkbox"/>
                        <label for="aceiteTermosUso" class="d-inline">
                            Eu estou de acordo com o
                            <a href="https://pdf.vexsoft.com.br/Contrato_VEXSOFT_Modelo_V1.0.pdf" target="_blank">contrato de adesão</a> e a
                            <a href="https://vexsoft.com.br/politica-de-privacidade/" target="_blank">política de privacidade</a>
                        </label>
                    </div>
                    <div class="form-group">
                        <input id="aceiteEmailNovidades" v-model="aceiteEmailNovidades" type="checkbox"/>
                        <label for="aceiteEmailNovidades" class="d-inline">
                            Aceito receber e-mails com novidades sobre o produto
                        </label>
                    </div>

                    <div
                        class="g-recaptcha"
                        data-sitekey="6Ld2MxMpAAAAAFPrKzSYOlm9tlzY1UK3GxcF0tsp"
                        data-size="invisible">
                    </div>

                    <button class="btn btn-success" type="submit" v-on:click="validarCaptcha">Cadastrar empresa agora
                    </button>
                    <button v-if="devMode()" class="btn btn-primary" type="submit" v-on:click="fake">FakeData</button>

                    <div class="avisos">
                        <p><a href="https://esqueciminhasenha.vexsoft.com.br" style="font-size:10pt">Esqueci a minha
                            senha</a></p>
                        <p><a href="https://app.vexsoft.com.br" style="font-size:10pt">Já sou cadastrado</a></p>
                        <p>Inicie agora mesmo um teste de 7 dias.</p>
                        <p>
                            Em caso de dúvidas entre em contato conosco,
                            <br/>através de um de nossos telefones:
                            <br/>
                            <a href="tel:4430285935">(44) 3028-5935</a> ou
                            <a href="tel:44991035935">(44) 99103-5935</a>
                        </p>
                    </div>

                    <div v-if="devMode()" class="devModeOn">
                        <p>DEVMODE ON</p>
                    </div>
                    <div class="devModeOn">
                        <p>Data ultima atualização: {{ buildDate }}</p>
                    </div>
                    <div class="devModeOn">
                        <p>Origem: {{ pegarOrigem() }}</p>
                    </div>

                </div>
            </div>
        </div>
        <a class="float whatsapp-icone"
           href="https://api.whatsapp.com/send?phone=554430285935&text=Oi! Estou interessado no aplicativo de vistoria"
           target="_blank">
            <i class="fa fa-whatsapp my-float "></i>
        </a>
    </div>
</template>

<style>

.devModeOn {
    font-size: 8pt;
    color: gray;
    margin-top: 20px;
}

.recaptcha {
    width: fit-content;
    margin: 0 auto;
}

.avisos {
    margin-top: 20px;
    font-size: 10pt;
    color: gray;

}

.formulario-cadastro {
    background-color: white;
    padding: 20px;
    border: 1px solid #bbaea5;
}

.Password__badge {
    font-size: 10px !important;
    text-align: center !important;
}

.dropdown-toggle::after {
    border-top: none;
}

.autocomplete__icon {
    height: 25px !important;
}

.autocomplete__box {
    padding: 8px 5px !important;
}

.cadastre-se {
    margin-top: 30px;
    text-align: center;
}

.dirty {
    border-color: #5a5 !important;
    background: #efe !important;
}

.dirty:focus {
    outline-color: #8e8 !important;
}

.error {
    border-color: red !important;
    background: #fdd !important;
}

.error:focus {
    outline-color: #f99 !important;

}


.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 60px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    animation: pulse 2s infinite;
}

.whatsapp-icone:hover {

    color: #fff !important
}

.my-float {
    margin-top: 16px;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(90, 162, 78, 0);
    }

    50% {
        transform: scale(1.2);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

</style>
<script>
import axios from "axios";
import Vue from "vue";
import Password from "vue-password-strength-meter";
import vSelect from "vue-select";
import {date} from "../../../compilationTime.json";

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateCnpj(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);

    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

export default {
    components: {
        vSelect,
        Password
    },
    created() {
        // const vm = this;
        //
        // vm.$options.sockets.novaVistoriaRealizadaVex = data => {
        //     const obj = data;
        //     const totalVistorias = obj.total[0].quantidade;
        //     vm.totalVistoriasRealizadas = totalVistorias;
        //     console.log(data.total[0].quantidade)
        //
        //     vm.$notify({
        //         type: 'success',
        //         title: 'Nova vistoria realizada'
        //       });
        // }
        //
        // vm.$options.sockets.novaEmpresaCadastrada = data => {
        //   vm.$notify({
        //       type: 'success',
        //       title: 'Opa! Nova empresa cadastrada! Faça parte você também! 🚀🚀 '
        //     });
        // }
    },
    data() {
        return {
            buildDate: date,
            aceiteTermosUso: false,
            aceiteEmailNovidades: false,
            tokenNovaEmpresa: null,
            totalVistoriasRealizadas: 0,
            loading: false,
            mostrarMaisBeneficios: false,
            empresa: {
                nome: "",
                cnpj: "",
                contato: {
                    nome: "",
                    celular: "",
                    email: "",
                    senha: ""
                }
            },
        };
    },
    // mounted() {
    //     this.$refs.cnpjEmpresa.addEventListener('input', this.mascaraCnpj);
    // },
    // destroyed() {
    //     this.$refs.cnpjEmpresa.removeEventListener('input', this.mascaraCnpj);
    // },
    methods: {
        mascaraCnpj: function (e) {
            var v = e.target.value
            v=v.replace(/\D/g,"")
            v=v.replace(/^(\d{2})(\d)/,"$1.$2")
            v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
            v=v.replace(/(\d{4})(\d)/,"$1-$2")
            e.target.value = v
        },
        pegarOrigem: function () {
            let origem = this.$route.query.origem
            return origem == null ? 'CadastroDireto' : origem
        },
        pegarConsultor: function () {
            return this.$route.query.consultor
        },
        devMode: function () {
            return Vue.config.devtools;
        },
        mostrarMensagemBoasVindas: function () {
            this.$router.push('/mensagem-boas-vindas?token=' + this.tokenNovaEmpresa + '&email=' + encodeURI(this.empresa.contato.email))
        },
        fake: function () {
            const vm = this;
            const numeroAleatorio = Math.floor(Math.random() * 10000 + 1);
            vm.empresa.nome = `Empresa ${numeroAleatorio}`;

            vm.empresa.contato.nome = `Contato ${numeroAleatorio}`;
            vm.empresa.contato.celular = "(44) 99131-1023";
            vm.empresa.contato.email = `robson+${numeroAleatorio}@inovaclick.com.br`;
            vm.empresa.contato.senha = "inovaclick4312X";
        },
        validarCampos: function () {
            const vm = this;

            let obj = {
                camposPreenchidos: true,
                mensagem: ""
            };

            try {
                // Termos de aceite
                if (vm.aceiteTermosUso == false) throw "Termos de uso";

                // Empresa
                if (vm.empresa.nome == "") throw "Nome da empresa";
                // if (vm.empresa.cnpj == "") throw "Cnpj da empresa";
                // if (!validateCnpj(vm.empresa.cnpj)) throw "Cnpj da empresa";

                // Contato
                if (vm.empresa.contato.nome == "") throw "Nome";
                if (vm.empresa.contato.celular == "") throw "Telefone celular";
                if (vm.empresa.contato.email == "") throw "E-mail";
                if (!validateEmail(vm.empresa.contato.email)) throw "E-mail";
                if (vm.empresa.contato.senha == "") throw "Senha";
            } catch (e) {
                obj.camposPreenchidos = false;
                obj.mensagem = e;
            }

            return obj;
        },
        validarCaptcha(){
            try {
                const vm = this
                // eslint-disable-next-line
                grecaptcha.ready(function () {
                    // eslint-disable-next-line
                    grecaptcha.execute('6Ld2MxMpAAAAAFPrKzSYOlm9tlzY1UK3GxcF0tsp', { action: 'submit' })
                        .then(async function (token) {
                            vm.gravarEmpresa(token)
                        });
                });
            } catch (error) {
                console.error(error)
            }
        },
        gravarEmpresa: async function (tokenCaptcha) {
            const vm = this
            try {
                const clienteAceitouTermosUso = vm.aceiteTermosUso == true;
                if (!clienteAceitouTermosUso) {
                    this.$swal(
                        "Atenção!!",
                        `Para realizar o cadastro e iniciar o período de testes, você precisa estar de acordo com os termos de uso do VEX`,
                        "error"
                        );
                    return;
                }

                const validacaoCampos = vm.validarCampos();
                if (!validacaoCampos.camposPreenchidos) {
                    this.$swal(
                        "Ops!!",
                        `<strong>${validacaoCampos.mensagem}</strong> não foi preenchido corretamente.
                        <br /> Por favor, revise o formulário e tente novamente.`,
                        "error"
                        );
                    return;
                }

                if (vm.empresa.nome === vm.empresa.contato.email) {
                    this.$swal(
                        "Ops!!",
                        `O nome da empresa não pode ser igual ao seu email`,
                        "error"
                        );
                    return;
                }

                const regexLetraNumero = /[^A-Za-z0-9\u00C0-\u00FF ]+/
                const regexEspacoBranco = /\s+/

                if (regexEspacoBranco.test(vm.empresa.contato.senha)) {
                    this.$swal(
                        "Ops!!",
                        `<strong>A senha não pode contar espaços em branco</strong>.
                        <br /> Por favor, revise o formulário e tente novamente.`,
                        "error"
                        );
                    return;
                }

                if (regexLetraNumero.test(vm.empresa.contato.nome)) {
                    this.$swal(
                        "Ops!!",
                        `<strong>O seu nome deve conter apenas letras e números</strong>.
                        <br /> Por favor, revise o formulário e tente novamente.`,
                        "error"
                        );
                    return;
                }

                if (regexLetraNumero.test(vm.empresa.nome)) {
                    this.$swal(
                        "Ops!!",
                        `<strong>O nome da empresa deve conter apenas letras e números</strong>.
                        <br /> Por favor, revise o formulário e tente novamente.`,
                        "error"
                        );
                    return;
                }

                vm.loading = true;

                const origem = await this.pegarOrigem()
                const consultor = await this.pegarConsultor()
                const senha = vm.empresa.contato.senha
                const senhaSemEspacoBranco = senha.replace(regexEspacoBranco, '')
                const senhaSemEmojis = senhaSemEspacoBranco.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')

                axios.post('/nova-empresa', {
                    email: vm.empresa.contato.email,
                    nomeEmpresa: vm.empresa.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    contato: vm.empresa.contato.nome,
                    senha: senhaSemEmojis,
                    celular: vm.empresa.contato.celular,
                    origem: origem,
                    cnpj: vm.empresa.cnpj.replace(/[^\d]+/g,''),
                    receberEmailNovidades: vm.aceiteEmailNovidades,
                    token_captcha: tokenCaptcha,
                    consultor: consultor
                }).then(response => {
                    console.log(response.data)
                    vm.loading = false;
                    const tokenNovaEmpresa = response.data.token;
                    console.log(tokenNovaEmpresa)
                    vm.tokenNovaEmpresa = tokenNovaEmpresa;
                    vm.mostrarMensagemBoasVindas();
                }).catch(err => {
                    console.error(err)
                    let mensagemErro = 'Erro ao requisitar cadastro.'
                    if(err.message && err.message.toLowerCase().includes('network error'))
                        mensagemErro = 'Erro de rede! Cheque a sua conexão e tente novamente em alguns minutos.'
                    if(err?.response?.data?.erro)
                        mensagemErro = err.response.data.erro
                    this.$swal('Erro', mensagemErro, 'error')
                    vm.loading = false;
                })
            } catch (err) {
                this.$swal('Erro', err?.response?.data ?? err.message, 'error')
                vm.loading = false;
            }
        }
    }
};
</script>
